// @ts-strict-ignore
import mixpanel from 'mixpanel-browser';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import { TrackerService } from '@/track/tracker.service';
import _ from 'lodash';

export default class MixpanelTrackingService extends TrackerService {
  private static serviceInstance?: MixpanelTrackingService;

  constructor({ companyName, serverEmail, userName, userEmail, contractNumber }) {
    super();
    if (MixpanelTrackingService.serviceInstance) {
      return MixpanelTrackingService.serviceInstance;
    }
    mixpanel.init('40ec369f3886f9d8b7bfee0b048d2494', {
      api_host: 'https://telemetry.seeq.com/mixpanel',
    });

    mixpanel.identify(userEmail);
    mixpanel.people.set_once({
      contractNumber,
      serverEmail,
      userName,
      companyName,
      email: userEmail,
      seeqVersion: SEEQ_VERSION,
    });
    MixpanelTrackingService.serviceInstance = this;
  }

  trackEvent(category, action, information, uniqueEventId) {
    const trackInfo = { action, uniqueEventId };
    let parsedInformation = information;
    try {
      parsedInformation = JSON.parse(information);
    } catch (e) {
      // ignore, strings are perfectly valid;
    }
    if (_.isObject(parsedInformation)) {
      _.assign(trackInfo, parsedInformation);
    } else {
      _.assign(trackInfo, { information });
    }

    mixpanel.track(category, trackInfo);
  }

  static reset() {
    delete MixpanelTrackingService.serviceInstance;
  }
}
