// @ts-strict-ignore
import { useTranslation } from 'react-i18next';
import { CapsuleInput } from '@/investigate/customCondition/CapsuleInput.molecule';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { useFluxPath } from '@/core/hooks/useFluxPath.hook';
import _ from 'lodash';
import { ITEM_TYPES } from '@/trendData/trendData.constants';
import { Capsule, CAPSULE_GROUP_DATE_FORMAT } from '@/utilities/datetime.constants';
import classNames from 'classnames';
import { formatDuration as formatDateDuration, formatTime as formatDateTime } from '@/datetime/dateTime.utilities';
import { Icon } from '@/core/Icon.atom';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { CancelAndSave } from '@/core/CancelAndSave.molecule';
import { TextButton } from '@/core/TextButton.atom';
import {
  sqCapsuleGroupStore,
  sqDurationStore,
  sqTrendCapsuleStore,
  sqTrendStore,
  sqWorksheetStore,
} from '@/core/core.stores';
import { CapsuleGroupCapsule } from '@/tools/manualCondition/manualCondition.constants';
import { isValidCapsule } from '@/tools/manualCondition/conditionFormula.service';
import { splitUniqueId } from '@/trendData/trendCapsule.utilities';
import { setItemSelected, setTrendSelectedRegion } from '@/trendData/trend.actions';
import { pickSelectedRegion, setCapsules } from '@/tools/manualCondition/manualCondition.actions';

type Selection = { id: string; startTime: number; endTime: number };

interface CapsuleGroupIF {
  previewId: string;
}

export const CapsuleGroup: React.FunctionComponent<CapsuleGroupIF> = ({ previewId }) => {
  const { t } = useTranslation();

  const { capsules, isLoading } = useFlux(sqCapsuleGroupStore);
  const { selectedCapsules: selectedCapsulesFromStore, chartItems } = useFlux(sqTrendCapsuleStore);
  const selectedRegion = useFluxPath(sqTrendStore, () => sqTrendStore.selectedRegion);
  const timezone = useFluxPath(sqWorksheetStore, () => sqWorksheetStore.timezone);
  const displayRange = useFluxPath(sqDurationStore, () => sqDurationStore.displayRange);

  const range = displayRange
    ? {
        startTime: sqDurationStore.displayRange.start.valueOf(),
        endTime: sqDurationStore.displayRange.end.valueOf(),
        properties: [],
      }
    : undefined;

  const [formCapsule, setFormCapsule] = useState<Capsule>();
  const [selectionDrawn, setSelectionDrawn] = useState(false);
  const [editingOriginalCapsule, setEditingOriginalCapsule] = useState<CapsuleGroupCapsule>();
  const [editingCapsule, setEditingCapsule] = useState<CapsuleGroupCapsule>();
  const selectedCapsules = useMemo(
    () =>
      _.map(selectedCapsulesFromStore as Selection[], (capsule) => ({
        ...capsule,
        // Add the itemType property so that the capsule can be deselected easily
        itemType: ITEM_TYPES.CAPSULE,
      })),
    [selectedCapsulesFromStore],
  );

  const updateFormCapsuleRange = (isSelectionDrawn = selectionDrawn) => {
    if (!isSelectionDrawn && range) {
      // Provide a reasonable default so that the field is filled even when the user clears the selection.
      setFormCapsule({
        startTime: range.startTime + (range.endTime - range.startTime) / 4,
        endTime: range.endTime - (range.endTime - range.startTime) / 4,
        properties: [],
      });
    }
  };

  useEffect(() => {
    updateFormCapsuleRange();
  }, [selectedCapsulesFromStore, range?.startTime, range?.endTime]);

  useEffect(() => {
    // The form capsule is the same as the selection, but we must take care in updating the formCapsule so that a
    // partially filled capsule in the form is not overwritten by the empty selection.
    const isSelectionDrawn = sqTrendStore.isRegionSelected();
    setSelectionDrawn(isSelectionDrawn);
    if (isSelectionDrawn) {
      setFormCapsule({
        startTime: sqTrendStore.selectedRegion.min,
        endTime: sqTrendStore.selectedRegion.max,
        properties: [],
      });
    }

    updateFormCapsuleRange(isSelectionDrawn);
  }, [selectedRegion]);

  /**
   * Replaces the selected region with the capsule at the top of the form, this acts as
   * a preview of the capsule in the form.
   */
  const handleCapsuleInputChange = (capsule: Capsule) => {
    if (isValidCapsule(capsule)) {
      setTrendSelectedRegion(capsule.startTime, capsule.endTime);
    }

    setFormCapsule(capsule);
  };

  /**
   * Tests if the selection is a preview capsule for the series that we are editing.
   * Note: the start and end times are rounded then compared because sometimes the chart capsules seem to be rounded
   *
   * @param {Object} capsule - capsule that is potentially selected
   * @param {Object} selection - selection that is potentially for the capsule's preview
   * @returns {Boolean} true if the selection is a preview capsule for the capsule
   */
  const isSelectionPreviewCapsule = (capsule: CapsuleGroupCapsule, selection: Selection) =>
    splitUniqueId(selection.id).capsuleSetId === previewId &&
    Math.round(selection.startTime) === Math.round(capsule.startTime) &&
    Math.round(selection.endTime) === Math.round(capsule.endTime);

  /**
   * Test if the capsule's preview is selected
   *
   * @param {Object} capsule - capsule that could be selected
   * @returns {Boolean} true if the capsule is selected in the preview
   */
  const isSelected = (capsule: CapsuleGroupCapsule) =>
    _.some(selectedCapsules, (s) => isSelectionPreviewCapsule(capsule, s));

  /**
   * Toggles the capsule preview's selection
   *
   * @param {Object} capsule - capsule that should have its preview capsule selection status toggled
   */
  const togglePreviewSelection = (capsule: CapsuleGroupCapsule) => {
    if (isSelected(capsule)) {
      _.chain(selectedCapsules)
        .filter((s) => isSelectionPreviewCapsule(capsule, s))
        .forEach((s) => setItemSelected(s, false))
        .value();
    } else {
      _.chain(chartItems)
        .flatMap('capsules')
        .filter((s: Selection) => isSelectionPreviewCapsule(capsule, s))
        .forEach((s: Selection) => setItemSelected({ ...s, itemType: ITEM_TYPES.CAPSULE }, true))
        .value();
    }
  };

  /**
   * Test if the capsule is being edited inline
   *
   * @param {CapsuleGroupCapsule} capsule - capsule from the capsules list
   * @returns {Boolean} true if the capsule is being edited
   */
  const isEditingCapsule = (capsule: CapsuleGroupCapsule) => capsule === editingOriginalCapsule;

  const checkIsValidCapsule = () => formCapsule && isValidCapsule(formCapsule);

  /**
   * formats a time as CAPSULE_GROUP_DATE_FORMAT
   *
   * @param {Number} time - time in ms
   */
  const formatTime = (time: number) => formatDateTime(time, timezone, CAPSULE_GROUP_DATE_FORMAT);

  /**
   * Enter inline editing mode for the capsule
   *
   * @param {Object} capsule - capsule to edit from capsules list
   */
  const editCapsule = (capsule: CapsuleGroupCapsule) => {
    setEditingOriginalCapsule(capsule);
    setEditingCapsule(_.cloneDeep(capsule));
  };

  /**
   * Removes a capsule. If the capsule is from selection it is deselected, otherwise it is removed from the capsule.
   *
   * @param {Object} capsule - capsule to remove
   */
  const removeCapsule = (capsule: CapsuleGroupCapsule) => {
    if (capsule.id) {
      const selection = _.find(selectedCapsules, ['id', capsule.id]);
      setItemSelected(selection, false);
    } else {
      setCapsules(_.without(capsules, capsule));
    }
  };

  /**
   * Updates a capsule by replacing the beforeCapsule with the afterCapsule
   *
   * @param {Object} beforeCapsule - original capsule that exists in the capsules list
   * @param {Object} afterCapsule - the capsule that should replace the beforeCapsule
   */
  const updateCapsule = (beforeCapsule: CapsuleGroupCapsule, afterCapsule: CapsuleGroupCapsule) => {
    // If capsule has an id it is linked to the selection, deselect the capsule to maintain consistency
    if (beforeCapsule.id) {
      const selection = _.find(selectedCapsules, ['id', beforeCapsule.id]);
      setItemSelected(selection, false);
    }

    _.chain(capsules)
      .without(beforeCapsule)
      // drop id since it isn't the same as the selection anymore.
      .concat(_.omit(afterCapsule, ['id']) as CapsuleGroupCapsule)
      .tap(setCapsules)
      .value();
  };

  /**
   * Exit inline editing mode
   *
   * @param {Boolean} save - if true commit the edit, otherwise discard changes
   */
  const closeEditCapsule = (save: boolean) => {
    const isChanged =
      editingOriginalCapsule.startTime !== editingCapsule.startTime ||
      editingOriginalCapsule.endTime !== editingCapsule.endTime;
    if (save && isChanged) {
      updateCapsule(editingOriginalCapsule, editingCapsule);
    }

    setEditingOriginalCapsule(undefined);
    setEditingCapsule(undefined);
  };

  /**
   * formats a duration
   *
   * @param {Number} startTime - start in ms
   * @param {Number} endTime - end in ms
   */
  const formatDuration = (startTime: number, endTime: number) => {
    return formatDateDuration(endTime - startTime, { simplify: true });
  };

  return (
    <div className="flexRowContainer">
      <div id="specSelectedRangePane" className="sqGrayBox ptb10 mtb10">
        <CapsuleInput
          trackCategory="Capsule Group"
          trackAction="Form Capsule Change"
          capsuleWindow={formCapsule}
          onChange={handleCapsuleInputChange}
        />
        <div className="text-center pt5 pl1 pr1 pb1">
          <TextButton
            id="specAddSelectedRangeButton"
            label="ADD"
            testId="specAddSelectedRangeButton"
            onClick={() => {
              if (selectionDrawn) {
                pickSelectedRegion({});
              } else {
                pickSelectedRegion({ formCapsule });
              }
            }}
            disabled={!checkIsValidCapsule() || isLoading}
          />
        </div>
      </div>
      {isLoading && (
        <div className="mt15 mb0 flexColumnContainer flexFill flexCenter">
          <Icon icon="fa-circle-o-notch" extraClassNames="fa-xlg sq-text-primary fa-spin" />
        </div>
      )}
      {!!capsules.length && !isLoading && (
        <div className="tableWrapper mt15 mb0 small max-height-800">
          <table className="table table-striped table-condensed mb0 fixedHeaderTable">
            <thead>
              <tr>
                <th>{t('START')}</th>
                <th>{t('END')}</th>
                <th className="width-18" />
                <th className="width-18" />
              </tr>
            </thead>
            <tbody id="specCapsuleListPane">
              {_.map(capsules, (capsule, index) => (
                <Fragment key={`${capsule.startTime}-${capsule.endTime}-${index}`}>
                  <HoverTooltip
                    text={`${capsule.startTime}-${capsule.endTime}`}
                    delay={500}
                    formattedText={
                      <>
                        {t('DURATION')}: {formatDuration(capsule.startTime, capsule.endTime)}
                      </>
                    }>
                    <tr
                      className={classNames('specCapsuleListRow aggressiveWordBreak cursorPointer', {
                        selectedTableRow: isSelected(capsule),
                      })}
                      onClick={() => togglePreviewSelection(capsule)}
                      data-testid={`capsule-${+index + 1}`}
                      id={`capsule_${capsule.id ?? +index + 1}`}
                      key={`${capsule.startTime}-${capsule.endTime}`}>
                      <td>{formatTime(capsule.startTime)}</td>
                      <td>{formatTime(capsule.endTime)}</td>
                      <td className="nowrap" onClick={(e) => e.stopPropagation()}>
                        {!isEditingCapsule(capsule) && (
                          <Icon
                            icon="fa-pencil"
                            tooltip="EDIT"
                            testId={`edit-capsule-${+index + 1}`}
                            onClick={() => editCapsule(capsule)}
                          />
                        )}
                        {isEditingCapsule(capsule) && <Icon icon="fa-pencil" type="inherit" />}
                      </td>
                      <td className="nowrap" onClick={(e) => e.stopPropagation()}>
                        <Icon
                          icon="fa-remove"
                          tooltip="REMOVE"
                          testId={`remove-capsule-${+index + 1}`}
                          onClick={() => removeCapsule(capsule)}
                        />
                      </td>
                    </tr>
                  </HoverTooltip>
                  {isEditingCapsule(capsule) && (
                    <tr key={`${capsule.startTime}-${capsule.endTime}-edit`} data-testid={`edit-${+index + 1}`}>
                      <td colSpan={4} className="borderTopNoneImportant specEditCapsulePane">
                        <div className="sqGrayBox pt10 pb10 mb10 mb5 pl0 pr0">
                          <CapsuleInput
                            trackCategory="Capsule Group"
                            trackAction="Capsule Edit"
                            capsuleWindow={editingCapsule}
                            onChange={setEditingCapsule}
                          />
                          <div className="text-center pt5">
                            <CancelAndSave
                              submitFn={() => closeEditCapsule(true)}
                              cancelFn={() => closeEditCapsule(false)}
                              values={[]}
                              btnDisabled={false}
                              submitClassNames="btn-sm"
                              cancelClassNames="btn-sm mr10"
                              cancelBtnTestId={`cancel-edit-${+index + 1}`}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
