// @ts-strict-ignore
import _ from 'lodash';
import { findItemIn, getAllItems, getTrendItemScopedTo, getTrendStores } from '@/trend/trendDataHelper.utilities';
import { API_TYPES, DISPLAY_MODE, EDIT_MODE } from '@/main/app.constants';
import { getAssetFromAncestors } from '@/utilities/httpHelpers.utilities';
import { sqItemsApi, sqSystemApi } from '@/sdk';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import { SEEQ_VERSION } from '@/services/buildConstants.service';
import {
  sqInvestigateStore,
  sqScatterConditionStore,
  sqWorkbenchStore,
  sqWorkbookStore,
  sqWorkstepsStore,
} from '@/core/core.stores';
import { decorate } from '@/trend/trendViewer/itemDecorator.utilities';
import {
  decorateItemWithProperties,
  getNamePrefix,
  getToolType,
  isStringSeries,
  isUserCreatedType,
} from '@/utilities/utilities';
import { errorToast } from '@/utilities/toast.utilities';
import { getItemForTool } from '@/investigate/investigate.utilities';
import { ITEM_DATA_STATUS, ITEM_TYPES } from '@/trendData/trendData.constants';
import { applyConfigUpgrade } from '@/utilities/configUpgrader.utilities';
import { flux } from '@/core/flux.module';
import { PUSH_IGNORE } from '@/core/flux.service';
import { update } from '@/utilities/derivedDataTree.utilities';
import { clearCapsules, loadFormulaAndSetSelection } from '@/tools/manualCondition/capsuleGroup.actions';
import { PREVIEW_TREND_TOOLS, TREND_TOOLS } from '@/toolSelection/investigate.constants';
import { isForbidden } from '@/utilities/redaction.utilities';
import { getDefaultName } from '@/utilities/formula.utilities';
import { PluginOutputV1 } from '@/sdk/model/PluginOutputV1';
import { PLUGIN_CATEGORY } from '@/plugin/pluginHost.constants';
import {
  cancelPreviewCapsules,
  removePreviewCapsules,
  setEditModeForCapsuleSet,
  setEditModeForSeries,
  setTrendItemProps,
  setTrendSelectedRegion,
} from '@/trendData/trend.actions';
import { setBrowsePanelCollapsed, tabsetChangeTab } from '@/worksheet/worksheet.actions';
import { setSelectedRegion } from '@/scatterPlot/scatterPlot.actions';
import { executeAddOnTool, setDisplayMode } from '@/toolSelection/investigateHelpers.actions';
import { loadDatafile } from '@/tools/importDatafile/importDatafile.actions';
import { clearOutstandingDebouncedAsyncFunctions } from '@/utilities/asyncDebounce';

/**
 * Service providing investigate actions
 */

export const DEFAULT_WINDOW_DETAILS = 'toolbar=1,location=0,scrollbars=1,statusbar=1,menubar=1,resizable=1';

let addOnToolWindows = {};

export function updateDerivedDataTree() {
  update()();
}

/**
 * Sets the active tool.
 *
 * @param {String} toolId - the ID of the tool to display. Must be one of INVESTIGATE_TOOLS.
 * @param {String} [mode] - One of DISPLAY_MODE. If not provided it uses the tool's
 *   defaultDisplayMode, if set, or falls back to DISPLAY_MODE.NEW
 * @param {Boolean} [changeTab] - If true changes to the investigate tab
 */
export function setActiveTool(toolId: string, mode?: string, changeTab = true) {
  const tool = _.find(sqInvestigateStore.allTools, ['id', toolId]) as any;
  if (!tool) {
    throw new TypeError(`${toolId} is not a valid tool`);
  }
  removePreviewCapsules();
  cancelPreviewCapsules();
  clearOutstandingDebouncedAsyncFunctions();
  setEditModeForSeries(null);
  setEditModeForCapsuleSet(null);
  setBrowsePanelCollapsed(false);
  clearCapsules();
  if (!sqWorkbookStore.isReportBinder && changeTab) {
    tabsetChangeTab('sidebar', 'investigate');
  }

  if (tool.type !== SeeqNames.Types.AddOnTool) {
    flux.dispatch('INVESTIGATE_SET_ACTIVE_TOOL', { tool: toolId });
    setDisplayMode(mode || tool.defaultDisplayMode || DISPLAY_MODE.NEW);
  } else {
    executeAddOnTool(tool);
  }
}

/**
 * Parameterize and sanitize target URL
 */
export function prepareTargetUrl(url: string) {
  const parameterizedUrl = _.chain(url)
    .replace('{workbookId}', sqWorkbenchStore.stateParams.workbookId)
    .replace('{worksheetId}', sqWorkbenchStore.stateParams.worksheetId)
    .replace('{workstepId}', sqWorkstepsStore.current.id)
    .replace('{seeqVersion}', SEEQ_VERSION)
    .value();

  return parameterizedUrl;
}

/**
 * If the named add-on tool window is open and we have a reference to it then set focus to the window. Otherwise
 * open a new named add-on tool window.
 */
export function focusOrOpenNamedWindow(url: string, windowName: string, details) {
  if (_.isUndefined(addOnToolWindows[windowName]) || _.get(addOnToolWindows[windowName], 'closed')) {
    addOnToolWindows[windowName] = window.open(url, windowName, details);
  } else {
    addOnToolWindows[windowName].focus();
  }
}

/**
 * Sets the text to use to filter the available tools and categories
 *
 * @param {String} filter - The string by which to filter the list of tools
 */
export function setToolFilter(filter) {
  flux.dispatch('INVESTIGATE_SET_TOOL_FILTER', { filter });
}

/**
 * Sets the item that is being investigated using one or more of the tools.
 *
 * @param {String|Object} idOrItem - The ID of the item to be investigated or an actual item
 * @param {Object} [props] - Additional properties to associate with the item. Useful for passing along additional
 *   information to the panel that loads the item.
 */
export function setItem(idOrItem, props?) {
  const item = _.isObject(idOrItem) ? idOrItem : findItemIn(getTrendStores(), idOrItem);
  if (!item) {
    clearInvestigatedItem();
  } else {
    flux.dispatch('INVESTIGATE_SET_ITEM', { item, props });
  }
}

/**
 * Sets an item which does not exist in a store as the investigate item.
 *
 * @param {object} item - the item
 */
export function setNonStoreItem(item) {
  flux.dispatch('INVESTIGATE_SET_ITEM', { item });
}

/**
 * Clears the item being investigated.
 */
export function clearInvestigatedItem() {
  flux.dispatch('INVESTIGATE_CLEAR_ITEM');
}

/**
 * Sets the search name.
 *
 * @param {String} tool - The name of the tool, one of TREND_TOOLS.
 * @param {String} name - The name for the search.
 */
export function setSearchName(tool, name) {
  flux.dispatch('TOOL_SET_SEARCH_NAME', { type: tool, name }, PUSH_IGNORE);
}

/**
 * Sets the state of the expandable advanced parameters section.
 *
 * @param {String} type - The type of the tool, one of TREND_TOOLS.
 * @param {Boolean} collapsed - True if the advanced section is collapsed.
 */
export function setAdvancedParametersCollapsedState(type, collapsed) {
  flux.dispatch('TOOL_SET_ADVANCED_PARAMETERS_COLLAPSED_STATE', {
    type,
    collapsed,
  });
}

/**
 * Sets the derived data panel open state
 *
 * @param {Boolean} derivedDataPanelOpen - True if open; false for closed.
 */
export function setDerivedDataPanelOpen(derivedDataPanelOpen) {
  flux.dispatch('INVESTIGATE_SET_DERIVED_DATA_PANEL_OPEN', {
    derivedDataPanelOpen,
  });
  if (derivedDataPanelOpen && _.isNil(sqInvestigateStore.derivedDataTree)) {
    update()();
  }
}

/**
 * Load, format, and persist add-on tools array
 */
export function loadAddOnTools() {
  return sqSystemApi
    .getAddOnTools()
    .then(({ data }) => {
      const addOnTools = _.chain(data.addOnTools)
        .map((et) => _.omit(et, ['href', 'effectivePermissions', 'isArchived']))
        .map((et) => _.assign(et, { parentId: TREND_TOOLS.ADDON_TOOLS_GROUP }))
        .value();

      flux.dispatch('INVESTIGATE_SET_ADDON_TOOLS', { addOnTools });
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

/**
 * Filters the supplied array of plugins to those that have the "ToolPane" category and sets them in the store.
 *
 * @param plugins - an array of plugins
 */
export function setPluginTools(plugins: PluginOutputV1[] = []) {
  _.chain(plugins)
    .filter((plugin) => PLUGIN_CATEGORY.TOOL_PANE === plugin.category)
    .map((plugin) => ({
      ...plugin,
      id: `${plugin.identifier}`,
      parentId: 'addon-tools-group',
    }))
    .map((plugin) => _.pick(plugin, ['id', 'name', 'description', 'icon', 'parentId', 'type']))
    .thru((pluginTools) => {
      flux.dispatch('INVESTIGATE_SET_PLUGIN_TOOLS', { pluginTools });
    })
    .value();
}

/**
 * Sets or adds, if multiple, the item for one of the parameters used in the formula.
 *
 * @param {String} type - The type of the tool, one of TREND_TOOLS.
 * @param {String} name - The name of the parameter
 * @param {Object|undefined} item - The item. Undefined is supported for single item parameters since that is what
 * sq-select-item passes when an item is unselected. Multi-select must use the onRemove() callback along with
 * unsetParameterItem.
 */
export function setParameterItem(type: string, name: string, item?: any) {
  flux.dispatch('TOOL_SET_PARAMETER_ITEM', { type, name, item });
}

/**
 * Sets or adds, if multiple, the item for one of the parameters used in the formula.
 *
 * @param {String} type - The type of the tool, one of TREND_TOOLS.
 * @param {String} name - The name of the parameter
 * @param {String[]} itemTypes - The item types that should be added, using ITEM_TYPES
 * @param {String[]} excludedIds - The ids that should not be added
 * @param {boolean} excludeStringSignals - True to exclude string signals
 */
export function setParameterItemFromDetailsPane(
  type: string,
  name: string,
  itemTypes: ITEM_TYPES[],
  excludedIds: string[],
  excludeStringSignals = false,
) {
  _.chain(
    getAllItems({
      excludeDataStatus: [ITEM_DATA_STATUS.REDACTED],
      itemTypes,
    }),
  )
    .reject((item) => _.includes(excludedIds, item.id))
    .reject((item) => (excludeStringSignals ? isStringSeries(item) : false))
    .thru((items) => decorate({ items }))
    .forEach((item) => setParameterItem(type, name, item))
    .value();
}

/**
 * Unsets or removes, if multiple, the item for one of the parameters used in the formula. Only useful for
 * parameters that are an array of items and used in conjunction with the multi-select functionality of
 * sq-select-item.
 *
 * @param {String} type - The type of the tool, one of TREND_TOOLS.
 * @param {String} name - The name of the parameter
 * @param {Object} item - The item to unset
 */
export function unsetParameterItem(type: string, name: string, item) {
  flux.dispatch('TOOL_UNSET_PARAMETER_ITEM', { type, name, item });
}

/**
 * Sets the maximum duration that a capsule can be; used when creating a new capsule set.
 *
 * @param {String} type - The type of the tool, one of TREND_TOOLS.
 * @param {Number} value - The number that indicates how long the duration is
 * @param {String} units - The units that the value represents
 */
export function setMaximumDuration(type: string, value: number | null, units: string, valid?) {
  flux.dispatch('TOOL_SET_MAXIMUM_DURATION', { type, value, units, valid });
}

/**
 * Closes the active tool, returning the UI to either the Overview or the investigate item view.
 */
export function closeInvestigationTool() {
  clearInvestigatedItem();
  setActiveTool(TREND_TOOLS.OVERVIEW, DISPLAY_MODE.NEW, false);
}

/**
 * Retrieves the calculation from the API and dispatches all necessary requests to re-populate the edit form for
 * the calculation.
 *
 * @param {String} id - The ID of the calculated item
 * @param {EDIT_MODE} [editMode=EDIT_MODE.NORMAL] - Duplicate the calculated item instead of loading it, either to
 *   Formula or the same tool as it already is in
 * @returns {Promise} that resolves once the calculation is retrieved
 */
export function loadToolForEdit(id: string, editMode = EDIT_MODE.NORMAL) {
  const item = findItemIn(getTrendStores(), id);
  // For signals/conditions created by a Datafile, it is the Datafile that must be edited
  if (_.get(item, 'calculationType') === TREND_TOOLS.IMPORTDATAFILE) {
    loadDatafile(_.get(item, 'assets[0].id', id));
    return Promise.resolve();
  }

  return sqItemsApi
    .getItemAndAllProperties({ id })
    .then(({ data: item }) => {
      setTrendItemProps(item.id, { scopedTo: item.scopedTo }, PUSH_IGNORE);
      if (!isUserCreatedType(item.type)) {
        return Promise.reject('Only user-created items can be loaded into a tool for edit.');
      }

      // Fetch the tool item formula and parameters
      return (
        getItemForTool(item)
          .then((response) => response.data as any)
          // Add swap source to those parameter items that are swaps
          .then((toolItem) =>
            _.chain(getFormulaParameters(toolItem))
              .reject('unbound')
              .map((parameter: any) =>
                sqItemsApi
                  .getItemAndAllProperties({ id: parameter.item.id })
                  .then(({ data: { properties } }) => {
                    const swapSourceId = _.chain(properties)
                      .find(['name', SeeqNames.Properties.SwapSourceId])
                      .get('value')
                      .toUpper()
                      .thru((value) => (_.isEmpty(value) ? undefined : value))
                      .value();
                    const valueUnitOfMeasure = _.chain(properties)
                      .find(['name', SeeqNames.Properties.ValueUom])
                      .get('value')
                      .value();
                    const numberFormat = _.chain(properties)
                      .find(['name', SeeqNames.Properties.NumberFormat])
                      .get('value')
                      .value();
                    return {
                      ...parameter,
                      item: {
                        ...decorateItemWithProperties(
                          _.omitBy(
                            {
                              ...parameter.item,
                              properties,
                              swapSourceId,
                              valueUnitOfMeasure,
                              numberFormat,
                            },
                            _.isNil,
                          ),
                        ),
                        assets: [getAssetFromAncestors(parameter.item.ancestors)],
                      },
                    };
                  })
                  .catch((error) => {
                    if (isForbidden(error)) {
                      return {
                        ...parameter,
                        item: {
                          ...parameter.item,
                          redacted: true,
                        },
                      };
                    }

                    return Promise.reject(error);
                  }),
              )
              .thru((promises) => Promise.all(promises))
              .value()
              .then((parameters) => [{ ...item, formula: toolItem.formula, parameters }, toolItem]),
          )
      );
    })
    .then(([item, toolItem]) => {
      const uiConfig = _.find(item.properties, ['name', SeeqNames.Properties.UIConfig]);
      let config = editMode !== EDIT_MODE.COPY_TO_FORMULA && uiConfig ? JSON.parse(uiConfig.value) : { id };
      config.type = editMode !== EDIT_MODE.COPY_TO_FORMULA ? getToolType(item) : TREND_TOOLS.FORMULA;

      item = decorateItemWithProperties(item);
      // Set additional properties that are not stored in UIConfig
      _.assign(
        config,
        _.pick(item, ['id', 'name', 'scopedTo', 'parameters', 'formula', 'signalMetadata', 'conditionMetadata']),
      );

      // Apply the upgrade steps to the config
      config = applyConfigUpgrade(config, config.configVersion);

      if (config.selectedRegion) {
        setTrendSelectedRegion(config.selectedRegion.min, config.selectedRegion.max);
      } else if (config.type === TREND_TOOLS.SCATTER_CONDITION) {
        const selectedRegion = sqScatterConditionStore.getSelectedRegionFromFormula(config.formula, config.parameters);
        setSelectedRegion(selectedRegion);
      }

      if (config?.formula?.indexOf('.validValues()') > 0) {
        _.assign(config, { useValidValues: true });
      }

      addCustomConfigProperties(config, toolItem);

      if (editMode !== EDIT_MODE.NORMAL) {
        // Copies need name prefix/index to be identified and  will not have an ID yet
        const oldName = config.name;
        delete config.id;
        delete config.name;

        return getDefaultName(getNamePrefix(oldName), getTrendItemScopedTo(id), [
          SeeqNames.API.Flags.ExcludeGloballyScoped,
        ]).then((indexedName) => {
          config.name = indexedName;
          return config;
        });
      }

      return config;
    })
    .then((config) => {
      flux.dispatch('TOOL_REHYDRATE_FOR_EDIT', config);
      setActiveTool(config.type, DISPLAY_MODE.EDIT);

      // Edit modes (editingIds) are cleared by setActiveTool above, so set them appropriately for the type now
      if (editMode === EDIT_MODE.NORMAL) {
        if (config.type === TREND_TOOLS.COMPOSITE_SEARCH || config.type === TREND_TOOLS.MANUAL_CONDITION) {
          setEditModeForCapsuleSet(id);
        } else if (_.includes(PREVIEW_TREND_TOOLS, config.type)) {
          setEditModeForSeries(id);
        }
      }

      // setActiveTool clears capsules from the capsule group store, so this logic must appear after the above
      // call
      if (config.type === TREND_TOOLS.MANUAL_CONDITION) {
        return loadFormulaAndSetSelection({
          formula: config.formula,
        });
      }
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error, displayForbidden: true });
    });
}

/**
 * Allows non-formula-based API types (e.g. THRESHOLD_METRIC) to encode type-specific item properties as standard
 * parameters. Formula based calculated items just return the item's parameters unchanged.
 *
 * @param {Object} item - the item for which parameters should be returned
 * @returns {Array} an array of item parameters
 */
export function getFormulaParameters(item) {
  switch (item.type) {
    case API_TYPES.THRESHOLD_METRIC:
      // The threshold items supplied by the user (not isGenerated) must be returned here so that they end up in
      // originalParameters and therefore are available as options in item parameter dropdown
      return _.chain(item.thresholds)
        .reject('isGenerated')
        .map((t) => ({
          item: t.item,
          name: `threshold${t.priority.level > 0 ? 'P' : 'N'}${Math.abs(t.priority.level)}`,
          unbound: false,
        }))
        .concat(
          _.map(
            ['measuredItem', 'boundingCondition'],
            (key) =>
              item[key] && {
                item: item[key],
                name: key,
                unbound: false,
              },
          ),
        )
        .compact()
        .value();
    default:
      return item.parameters;
  }
}

/**
 * Add non-item properties to the config for tools that are not based on a formula.
 *
 * @param {Object} config - the configuration object
 * @param {Object} item - the item for which the tool is being loaded
 */
export function addCustomConfigProperties(config, item) {
  switch (item.type) {
    case API_TYPES.THRESHOLD_METRIC:
      _.assign(
        config,
        _.pick(item, [
          'processType',
          'aggregationFunction',
          'duration',
          'period',
          'neutralColor',
          'boundingConditionMaximumDuration',
          'thresholds',
        ]),
      );
  }
}
