import bandPass from '@/../public/img/band_pass.png';
import bandStop from '@/../public/img/band_stop.png';
import highPass from '@/../public/img/high_pass.png';
import lowPass from '@/../public/img/low_pass.png';

export const DIGITAL_FILTER_VALUES = {
  BAND_PASS: 'band-pass',
  BAND_STOP: 'band-stop',
  HIGH_PASS: 'high-pass',
  LOW_PASS: 'low-pass',
};
export const DIGITAL_FILTER_IMAGES = {
  BAND_PASS: bandPass,
  BAND_STOP: bandStop,
  HIGH_PASS: highPass,
  LOW_PASS: lowPass,
};
export const DIGITAL_FILTER_CUTOFF = { value: undefined, units: undefined };
export const DIGITAL_FILTER_WINDOW_TO_CUTOFF_RATIO = 4;
export const DIGITAL_FILTER_TAPS_MIN = 3;
export const DIGITAL_FILTER_PERIOD_CUTOFF_RATIO = 5;
export const DIGITAL_FILTER_PERIOD_CUTOFF2_RATIO = 50;
export const DIGITAL_FILTER_PRECISION = 6; // number of digits after the decimal to round too (includes zeros)
export const DIGITAL_FILTER_MIN_CUTOFF_RATIO = 2;
